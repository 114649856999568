@tailwind base;
@tailwind components;
@tailwind utilities;



/* Add this to your CSS file */
.btn-copy {
  cursor: pointer;
}

.btn-copy:hover {
  background-color: #1d4ed8; /* Slightly darker blue on hover */
}

  
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid white;
    width: 16px;
    height: 16px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.date-picker-white::-webkit-calendar-picker-indicator {
    filter: invert(1);
    color: azure;
  }
  